import React from "react"

import Layout from "../components/layout"
import TextBlock from "../components/text-block"
import Seo from "../components/seo"
import { Helmet } from "react-helmet"
import theme from "../theme"

import styled from "styled-components"

import IconLoad from "../assets/icons/load.svg"

const TextBlockCentered = styled(TextBlock)`
  display: flex;
  flex-direction: column;
`

const LoadContainer = styled.div`
  width: ${1 * theme.layout.s.grid * 100}vw;
  height: ${1 * theme.layout.s.grid * 100}vw;

  justify-self: center;
  align-self: center;

  @media (min-width: ${theme.breakpoint.medium}) {
    width: ${1 * theme.layout.m.grid * 100}vw;
    height: ${1 * theme.layout.m.grid * 100}vw;
  }

  @media (min-width: ${theme.breakpoint.large}) {
    width: ${1 * theme.layout.l.grid * 100}vw;
    height: ${1 * theme.layout.l.grid * 100}vw;
  }

  svg {
    width: 100%;
    height: 100%;
    animation: infinite-spinning 2s infinite linear;
  }

  @keyframes infinite-spinning {
    from {
      transform: rotate(0deg);
      transform-origin: center;
    }
    to {
      transform: rotate(360deg);
      transform-origin: center;
    }
  }
`

const ServicePage = ({ location }) => (
  <Layout location={location}>
    <Seo title="Customer Portal" />
    <Helmet>
      <meta
        http-equiv="refresh"
        content={`2;url=https://patrickkrueger.atlassian.net/servicedesk/customer/portals`}
      />
    </Helmet>
    <TextBlockCentered bg="orange">
      <h1>Redirecting</h1>
      <LoadContainer>
        <IconLoad></IconLoad>
      </LoadContainer>
      <p>
        <a href="https://patrickkrueger.atlassian.net/servicedesk/customer/portals">
          Customer Portal
        </a>
      </p>
    </TextBlockCentered>
  </Layout>
)

export default ServicePage
